import React from 'react'
import { graphql } from 'gatsby'
import NavLayout from '../components/NavLayout.js'
import PageTitle from '../components/PageTitle.js'
import ImageGrid from '../components/ImageGrid.js'
import Media from 'react-media'

export default ({ data }) => {
  const paintings = data.allStrapiPainting.edges.map(painting => painting.node)
  return (
    <NavLayout>
      <PageTitle title="All Paintings" />

      <Media query="(min-width:500px)">
        {matches => (
          <ImageGrid
            images={paintings}
            numberOfColumns={matches ? 3 : 1}
            route={null}
          />
        )}
      </Media>
    </NavLayout>
  )
}

export const query = graphql`
  query {
    allStrapiPainting {
      edges {
        node {
          Title
          Medium
          Height
          Width
          Sold
          id
          Image {
            id
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`
